import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Store } from 'antd/lib/form/interface';
import styles from './AttendanceDetail.module.css';
import { Layout, Typography, Table, Form, Input, Tooltip, Modal, Button } from 'antd';
import { CriteriaForm, Checkbox, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm'
import { Header, ContentEx as Content, FunctionTitle, CommonMessage, FooterButtonArea, Footer } from '../../core/CorePageContents'

import * as Module from '../../../modules/partner/attendance/attendanceModule';
import * as Model from '../../../modules/partner/attendance/attendanceModel';
import { initMessages, setMessages, setModalMessages, message } from '../../../modules/core/commonMessageModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as CONSTANTS from '../../../constants/constants';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as Utils from '../../../utils/utils';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { initApiResult, storedApiResult, setApiResult } from '../../../modules/core/bffApiModule';
import { RightSquareFilled, LeftSquareFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { storedLoginUserInfo } from '../../../modules/core/authModule';
import { calcWorkTime } from '../../../utils/CalculateUtils'


import { AbolishCompanyListButton } from '../../core/parts/AbolishCompanyListButton'
import { MergeCompanyAnnotations } from '../../core/parts/MergeCompanyAnnotations'

interface titleInterface {
  title: string;
}

export const AttendanceDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDetailDataList);
  let allSelect = useSelector(Module.storedAllSelect);
  let criteria = useSelector(Module.storedCriteria);
  let total = useSelector(Module.storedDetailTotal);
  let primeCompanyId = useSelector(Module.storedPrimeCompanyId);
  let primeCompanyName = useSelector(Module.storedPrimeCompanyName);
  let projectId = useSelector(Module.storedProjectId);
  let projectName = useSelector(Module.storedProjectName);
  let belongCompanyId = useSelector(Module.storedBelongCompanyId);
  let belongCompanyName = useSelector(Module.storedBelongCompanyName);
  let firstPartnerCompanyId = useSelector(Module.storedFirstPartnerCompanyId);
  let existSubmittedChecked = useSelector(Module.storedExistSubmittedChecked);
  let existNotSubmittedChecked = useSelector(Module.storedExistNotSubmittedChecked);
  let modalVisibleState = useSelector(Module.storedModalVisibleState);
  let qualificationModalVisibleState = useSelector(Module.storedQualificationModalVisibleState);
  let info = useSelector(Module.storedSelectedAttendanceInfo);
  let editAvailableFlag = useSelector(Module.storedEditAvailableFlag);
  let existAvailableRow = useSelector(Module.storedExistAvailableRow);
  let loginUserInfo = useSelector(storedLoginUserInfo);
  let isLoading = useSelector(Module.storedLoading);
  let worktimeConfirmModalVisibleState = useSelector(Module.storedWorktimeConfirmModalVisibleState);
  let worktimeConfirmText = useSelector(Module.storedWorktimeConfirmText);
  let isContainInvalidInOutTime = useSelector(Module.storedIsContainInvalidInOutTime);
  let apiResult = useSelector(storedApiResult);
  let history = useHistory();
  let qualificationList = useSelector(Module.storedQualificationList);
  let existNotSubmitted = useSelector(Module.storedExistNotSubmitted);
  const [form] = Form.useForm();

  useEffect(() => {
    callGetDetailDataListApi();
  },
    [criteria]
  );

  /**
   * 月末の日付の取得
   * @param attendanceYearMonth 
   */
  const getLastDay = (attendanceYearMonth: string) => {
    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    return new Date(year, month, 0).getDate();
  }

  /**
   * 日毎の勤務実績部分の作成
   * @param attendanceYearMonth 
   */
  const getDaily = (attendanceYearMonth: string) => {
    let daysColumn = [];

    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    // 月の最終日の取得
    let lastDay = getLastDay(attendanceYearMonth);

    // 曜日文字列定義
    const weekDayChars = ['日', '月', '火', '水', '木', '金', '土']

    for (let i = 1; i <= lastDay; i++) {
      // 日付オブジェクトの作成
      let day = new Date(year, month - 1, i);
      let name = ""

      if (day.getDay() === 0 || day.getDay() === 6) {
        // 土日の場合は背景色をピンクにする
        name = styles.holiday;
      } else {
        name = styles.day;
      }

      daysColumn.push(
        {
          title: i,
          dataIndex: 'day' + i,
          key: 'day' + i,
          className: name,
          align: 'center' as 'center',
          children: [{
            title: weekDayChars[day.getDay()],
            className: name,
            align: 'center' as 'center',
            render: (test: string, row: Model.WorkRecordDetailRow) => {

              const firstInTime = row.dailyWorkStatusList[i - 1].firstInTime;
              const lastOutTime = row.dailyWorkStatusList[i - 1].lastOutTime;
              const isValid = isValidPeriod(row.dailyWorkStatusList[i - 1].meetQualificationRequirementType);
              const isTimeEmptyOrZero = (time: string) => {
                return time === "" || time === "0:00" || time === "00:00";
              }
              let workingTime = formatUtils.formatTime(row.dailyWorkStatusList[i - 1].workingTime);
              const diffStr = formatUtils.formatTime(calcWorkTime(firstInTime, lastOutTime, "00:00"));

              let className = "";
              const inOutClassName = Utils.isInversionInOutTime(firstInTime, lastOutTime) ? styles.invalidInOutTime :styles.inOutTIme;
              if (isValid) {
                if (isTimeEmptyOrZero(workingTime) && (!Utils.isNullOrEmpty(firstInTime) || !Utils.isNullOrEmpty(lastOutTime))) {
                  // 労働時間が設定されていないが入退場は設定されている
                  className = styles.diffWorking;
                } else if (!isTimeEmptyOrZero(workingTime) && diffStr !== workingTime) {
                  // 入退場から算出した労働時間と設定されている労働時間が異なる
                  className = styles.diffWorking;
                } else {
                  className = styles.working;
                }
              } else {
                className = styles.working;
              }

              if (isTimeEmptyOrZero(workingTime)
                && Utils.isNullOrEmpty(firstInTime)
                && Utils.isNullOrEmpty(lastOutTime)) {
                // 入退場が空 かつ 労働時間がゼロならば空にする
                workingTime = "";
              }
  
              let firstInTimeTag: any = "";
              let lastOutTimeTag: any = "";
              let workingTimeTag: any = "";
              if (isValid) {
                firstInTimeTag = Utils.isNullOrEmpty(firstInTime) ? <>&nbsp;</> : formatUtils.formatDateToTime(firstInTime);
                lastOutTimeTag = Utils.isNullOrEmpty(lastOutTime) ? <>&nbsp;</> : formatUtils.formatDateToTime(lastOutTime);
                if (isNotSubmitted(row)) {
                  // 未提出の場合は編集可能
                  workingTimeTag = <a onClick={() => onClickEdit(row, i - 1)}>{workingTime === "" ? "編集" : workingTime}</a>
                } else {
                  // 提出済みの場合は編集不可
                  workingTimeTag = workingTime === "" ? <>&nbsp;</> : workingTime;
                }
              } else {
                // 有効期間外であれば「－」を表示
                firstInTimeTag = '－';
                lastOutTimeTag = '－';
                workingTimeTag = '－';
              }

              return (
                <>
                  <div className={inOutClassName}>{firstInTimeTag}</div>
                  <div className={inOutClassName}>{lastOutTimeTag}</div>
                  <div className={className}>{workingTimeTag}</div>
                </>
              )
            }
          }]
        }
      );
    }
    return daysColumn;
  }

  /** 有効期間内かどうか */
  const isValidPeriod = (type: string) => {
    return type !== CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code;
  }

  /** 未提出（差戻含む）かどうかチェック */
  const isNotSubmitted = (row: Model.WorkRecordDetailRow) => {
    return isStatusNotSubmitted(row) || isStatusRemand(row) || row.approvalStatus === "";
  }

  /** 承認済＊かどうかチェック */
  const isStatusForceApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
  }

  /** 承認済かどうかチェック */
  const isStatusApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
  }

  /** 差戻かどうかチェック */
  const isStatusRemand = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
  }

  /** 未提出かどうかチェック */
  const isStatusNotSubmitted = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
  }

  /**
   * 全選択チェックボックスを表示するか
   * 勤怠提出者権限を保持、提出期限を過ぎていない、利用可能な勤怠（承認済以外）を表示している場合、表示
   */
  const isDisplayCheckBox = () => {
    if (loginUserInfo.authorityInfo.partnerAttendanceSubmitAuth === "0") {
      // 勤怠提出者権限を保持していない
      return false;
    }

    // 処理できる行がある場合に表示
    return existAvailableRow;
  }

  /**
   * CriteriaをStoreに保持
   * @param keyValueList 
   */
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordDetailCriteria = {
      attendanceYearMonth: criteria.attendanceYearMonth,
      primeCertificationNo: criteria.primeCertificationNo,
      workerLastName: criteria.workerLastName,
      workerFirstName: criteria.workerFirstName,
      workerLastNameKana: criteria.workerLastNameKana,
      workerFirstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      offset: criteria.offset,
      limit: criteria.limit,
      approved: criteria.approved,
      submitted: criteria.submitted,
      remand: criteria.remand,
      noSubmitted: criteria.noSubmitted
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setCriteria(newCriteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_DETAIL === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && total > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(total, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  if (window.location.search !== ""
    && criteria.attendanceYearMonth === ""
    && projectId === ""
    && belongCompanyId === "") {
    let urlParamStr = window.location.search.substring(1);

    let workAttendanceYearMonth = "";
    let workProjectId = "";
    let workBelongCompanyId = "";

    urlParamStr.split('&').forEach(param => {
      const temp = param.split('=');

      if (temp[0] === "attendanceYearMonth") {
        workAttendanceYearMonth = temp[1];
      }
      if (temp[0] === "projectId") {
        workProjectId = temp[1];
      }
      if (temp[0] === "belongCompanyId") {
        workBelongCompanyId = temp[1];
      }
    });

    if (Utils.isNullOrEmpty(workAttendanceYearMonth) || Utils.isNullOrEmpty(workProjectId) || Utils.isNullOrEmpty(workBelongCompanyId)) {
      // 1つでも設定されていないパラメータが存在する場合
      history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY);
    }

    let urlParamValues: Model.UrlParameter = {
      attendanceYearMonth: workAttendanceYearMonth
      , projectId: workProjectId
      , belongCompanyId: workBelongCompanyId
    }

    dispatch(Module.setUrlParameterValue(urlParamValues));
  } else if (window.location.search === ""
    && criteria.attendanceYearMonth === ""
    && projectId === ""
    && belongCompanyId === "") {
    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY);
  }

  if ("" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_DETAIL === apiResult.url
    && editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE) {
    if (existNotSubmitted) {
      // 編集不可の場合
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_DEADLINE_PASSED_PARTNER")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
  }

  // 有効期限を描画します。
  const renderDeadlineTerm = (value: string, row: Model.QualificationRow) => {
    return {
      children: (
        formatUtils.formatDuration(row.deadlineStartDate, row.deadlineEndDate)
      )
    }
  };

  /**
   * 資格一覧ダイアログの表示
   */
  const qualificationDialogColumns = [
    {
      title: '資格名'
      , width: '200px'
      , dataIndex: 'originalQualificationName'
      , align: 'left' as 'left'
      , render: (value: any, row: Model.QualificationRow) => {
        return (
          <>
            <span>{ row.originalQualificationName }</span>
          </>
        )
      }
    },
    {
      title: '認定日'
      , width: '100px'
      , dataIndex: 'certificationDate'
      , align: 'left' as 'left'
      , render: (value: string, row: Model.QualificationRow) => {
        return (
          formatUtils.formatDateSlash(row.certificationDate)
        )
      }
    },
    {
      title: '有効期間'
      , width: '200px'
      , dataIndex: 'deadlineTerm'
      , align: 'left' as 'left'
      , render: renderDeadlineTerm
    }
  ];

  /** 一覧部 */
  let columns = [
    {
      title: (
        <>
          <span>選択</span><br />
          <Checkbox
            className={styles.checkBox}
            checked={allSelect}
            onChange={(e: any) => dispatch(Module.onChangeAllSelect(e))}
            style={{ display: `${!isDisplayCheckBox() ? "none" : "inline"}` }}
          >
          </Checkbox>
        </>
      ),
      dataIndex: 'selectFlag',
      key: 'selectFlag',
      className: styles.select,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        if (loginUserInfo.authorityInfo.partnerAttendanceSubmitAuth === "0") {
          // 勤怠提出者権限を保持していない場合、表示しない
          return <></>
        } else if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE) {
          // 提出期限を過ぎている場合、表示しない
          return <></>
        } else if (!isStatusApproved(row) && !isStatusForceApproved(row)) {
          // 「承認済」「承認済＊」以外の場合、表示
          return (
            <Checkbox
              checked={row.selectFlag}
              onChange={(e) => onChangeCheck(e, row)}
            >
            </Checkbox>
          )
        } else {
          return <></>
        }
      }
    },
    {
      title: '状態',
      className: styles.status,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        let label = "";
        if (isStatusForceApproved(row)) {
          label = "承認済＊";
        } else {
          switch (row.approvalStatus) {
            case CONSTANTS.ATTENDANCE_STATUS_APPROVED:
              label = "承認済";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_CONFIRMED:
              label = "確認済";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED:
              label = "提出済";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_REMAND:
              label = "差戻";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED:
              label = "未提出";
              break;
          }
        }

        return (
          <>
            <span>{label}</span>
            <AbolishCompanyListButton historyId={row.workRecordSubmissionHistoryId} abolishCompanyFlag={row.abolishCompanyFlag} />
          </>)
      }

    },
    {
      title: (
        <>
          <span>元請認定番号</span><br />
          <span>氏名</span>
        </>
      ),
      className: styles.worker,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) =>
        <>
          <div className={styles.flexCell}>
            <div className={styles.contentLeft}>
              <span>{row.primeCertificationNo}</span><br />
              <span>{row.name}</span>
            </div>
            <Button
              name={"保持資格"}
              onClick={() => onClickQualification(row)}
              className={styles.materialSearchButton}
            >
              <i className="material-icons">search</i>
            </Button>
          </div>
        </>
    },
    {
      title: '一次協力会社',
      className: styles.firstPartner,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        return (
          <>
            <div className={styles.columnAlignLeft}>{row.firstPartnerCompanyName}</div>
          </>
        )
      }
    },
    {
      title: '項目',
      className: styles.item,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        return {
          children: (<>
            <div className={styles.totalDays}>入退場履歴</div>
            <div className={styles.totalWorking}>労働時間</div>
          </>)
        }
      }
    },
    {
      title: (
        <>
          <span>日数</span><br /><br />
          <span>合計</span>
        </>
      ),
      className: styles.total,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        return (
          <>
            <div className={styles.totalDaysValue}>{row.days}</div>
            <div className={styles.totalWorkingValue}>{row.totalWorkingTime}</div>
          </>
        )
      }
    },
    {
      title: '労働者出勤実績',
      className: styles.workingRecordTitle,
      align: 'center' as 'center',
      children: getDaily(criteria.attendanceYearMonth)
    }
  ];

  const callGetDetailDataListApi = () => {
    // パラメータが存在しない場合はデータを取得しない
    if (criteria.attendanceYearMonth === ""
      || projectId === ""
      || belongCompanyId === "") {
      return;
    }

    let statusList: Array<string> = [];
    if (criteria.approved) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_APPROVED);
    }
    if (criteria.submitted) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_CONFIRMED);
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED);
    }
    if (criteria.remand) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_REMAND);
    }
    if (criteria.noSubmitted) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED);
    }
    let params: Model.getDetailDataList.Request = {
      attendanceYearMonth: criteria.attendanceYearMonth,
      projectId: projectId,
      belongCompanyId: belongCompanyId,
      firstPartnerCompanyId: firstPartnerCompanyId,
      primeCertificationNo: criteria.primeCertificationNo,
      lastName: criteria.workerLastName,
      firstName: criteria.workerFirstName,
      lastNameKana: criteria.workerLastNameKana,
      firstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      status: statusList.toString(),
      offset: criteria.offset,
      limit: criteria.limit
    }
    dispatch(Module.getDetailDataListAsync(params));
  }

  if (CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_DAILY === apiResult.url) {

    dispatch(Module.setLoading(false));

    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
      && "" === apiResult.errorCode) {
      const setMessage: message = {
        message: messagesUtils.getMessage("SUCCESS_ATTENDANCE_PROCESS", ["労働時間の編集"])
        , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
      }
      dispatch(setMessages([setMessage]));

      // 労働時間編集ダイアログを閉じる
      dispatch(Module.reflectModalState(false));

      dispatch(initApiResult());

      callGetDetailDataListApi();
    }

  } else if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_CMN_STATUS === apiResult.url) {

      const messageText = messagesUtils.getMessageTextFromSession();
      const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
      if (messageText !== null) {
        const setMessage: message = {
          message: messageText
          , messageType: messageType
        }
      dispatch(setMessages([setMessage]));

      messagesUtils.clearSessionMessage();

      // 提出および提出取消処理完了の旨を伝えるダイアログを表示
      window.alert(messageText);
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // 提出、提出取消処理後
    callGetDetailDataListApi();
  }

  /**
   * 勤怠年月変更日イベント
   * @param addValue 
   */
  const onChangeMonth = (addValue: number) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let year = parseInt(criteria.attendanceYearMonth.substring(0, 4));
    let month = parseInt(criteria.attendanceYearMonth.substring(4, 6));

    // 変更後の日付インスタンスの作成
    let newDate = new Date(year, (month - 1) + addValue, 1);

    year = newDate.getFullYear();
    month = newDate.getMonth() + 1;

    criteriaDataTransfer([
      { key: "attendanceYearMonth", value: year.toString() + ("0" + month).slice(-2) }
    ]);
  }

  const searchDataList = (value: Store) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "primeCertificationNo", value: value.primeCertificationNo }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "firstPartnerCompanyNameKana", value: value.firstPartnerCompanyNameKana }
      , { key: "workerLastName", value: value.workerLastName }
      , { key: "workerFirstName", value: value.workerFirstName }
      , { key: "workerLastNameKana", value: value.workerLastNameKana }
      , { key: "workerFirstNameKana", value: value.workerFirstNameKana }
      , { key: "offset", value: 0 }
      , { key: "approved", value: value.approved }
      , { key: "submitted", value: value.submitted }
      , { key: "remand", value: value.remand }
      , { key: "noSubmitted", value: value.noSubmitted }
    ]);
  }

  /**
   * チェックボックス変更時イベント
   * @param e 
   * @param row 
   */
  const onChangeCheck = (e: CheckboxChangeEvent, row: Model.WorkRecordDetailRow) => {
    let transfer: Module.changeCheckTransfer = {
      targetDataRow: row,
      value: e.target.checked
    }

    dispatch(Module.onChangeRowSelect(transfer));
  }

  /**
   * テーブル検索条件変更時イベント（ページ、表示件数）
   * @param pagination 
   * @param filters 
   */
  const handleTableChange = (pagination: any, filters: any) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    if (criteria.limit !== pagination.pageSize) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  /**
   * 戻るボタン押下
   */
  const onClickBack = () => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY);
  }

  /**
   * 各種処理ボタン押下
   * @param processType 処理種別 
   */
  const onClickProcess = (processType: CONSTANTS.ATTENDANCE_PROCESS_TYPE) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let args: string[] = [];

    // 処理タイプ毎にメッセージを取得
    switch (processType) {
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
        args.push("提出");
        args.push("未提出または差戻")
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
        args.push("提出取消");
        args.push("提出済");
        break;
      default:
        return;
    }

    if (existSubmittedChecked && existNotSubmittedChecked) {
      // 未提出の勤怠と、提出済みの勤怠が混在して選択されている
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_ATTENDANCE_PROCESS", args)
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
      return;
    }

    // 確認ダイアログの表示
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_ATTENDANCE_PROCESS", [args[0]]))) {
      return;
    }

    // 勤怠提出の場合に「入退場時間のどちらかがあるのに労働時間が0時間」となっている勤怠がある場合は確認ダイアログを出す
    if (processType === CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT) {
      const isTimeZero = (time: string) => {
        return time === "0:00" || time === "00:00"
      }
      
      // 確認ダイアログ用の「勤怠がおかしい作業員名とその日付」を抽出する
      let warningTextList: string[];
      warningTextList = dataList.map((d) => {
        let rtn: string = "";
        if (d.selectFlag) {
          let alertDayList: number[] = [];
          for (let i = 0; i < d.dailyWorkStatusList.length; i++) {
            const ws = d.dailyWorkStatusList[i];
            if ((!Utils.isNullOrEmpty(ws.firstInTime) || !Utils.isNullOrEmpty(ws.lastOutTime)) && isTimeZero(ws.workingTime)) {
              alertDayList.push(i + 1);
            }
          }
          if (alertDayList.length > 0) {
            rtn = `　${d.name}：${alertDayList.map(d => `${d}日`).join("・")}`;
          }
        }
        return rtn;
      });
      warningTextList = warningTextList.filter(w => w !== "");

      // 確認ダイアログ用のメッセージが存在する（おかしい勤怠が存在する）場合は確認ダイアログを表示
      if (warningTextList.length > 0) {
        let confirmTxt = "以下の作業員が入退場履歴ありで労働時間0時間となっております。\r\n";
        confirmTxt += `${parseInt(criteria.attendanceYearMonth.substring(0, 4))}年${parseInt(criteria.attendanceYearMonth.substring(4, 6))}月分の勤怠をこのまま提出してよろしいですか？\r\n`;
        confirmTxt += `${warningTextList.join("\r\n")}`;
        dispatch(Module.setWorktimeConfirmText(confirmTxt))
        dispatch(Module.reflectWorktimeConfirmModalVisibleState(true));
        return;
      }
    }

    submitAttendance(processType);
  }

  const submitAttendance = (processType: CONSTANTS.ATTENDANCE_PROCESS_TYPE) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let args = [];
    // 処理タイプ毎にメッセージを取得
    switch (processType) {
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
        args.push("提出");
        args.push("未提出または差戻")
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
        args.push("提出取消");
        args.push("提出済");
        break;
      default:
        return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // リクエストデータの作成
    let destList: Array<Model.putStatus.Dest> = [];

    dataList.forEach((data: Model.WorkRecordDetailRow) => {
      if (data.approvalStatus !== CONSTANTS.ATTENDANCE_STATUS_APPROVED) {
        if (data.selectFlag) {
          let dest: Model.putStatus.Dest = {
            workRecordSubmissionHistoryId: data.workRecordSubmissionHistoryId,
            attendanceYearMonth: criteria.attendanceYearMonth,
            belongCompanyId: belongCompanyId,
            workerId: data.workerId,
            projectId: projectId,
            firstCompanyId: data.firstPartnerCompanyId,
            updateDatetime: data.updateDatetime
          }

          destList.push(dest);
        }
      }
    });

    // メッセージをセッションストレージに保管
    let message = messagesUtils.getMessage("SUCCESS_ATTENDANCE_PROCESS", [args[0]]);
    messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_SUCCESS);

    let putData: Model.putStatus.Request = {
      processType: processType,
      destList: destList
    }

    dispatch(Module.putAttendanceStatusAsync(putData));
  }

  /**
   * 編集/労働時間リンク押下時
   * @param row 
   * @param index 
   */
  const onClickEdit = (row: Model.WorkRecordDetailRow, index: number) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 労働時間をフォームに設定
    form.setFieldsValue({ workingTime: row.dailyWorkStatusList[index].workingTime });

    // 選択された勤怠年月日の情報をstateに保存
    let attendanceInfo: Model.SelectedAttendanceInfo = {
      workRecordSubmissionHistoryId: row.workRecordSubmissionHistoryId
      , primeCompanyId: primeCompanyId
      , belongCompanyId: belongCompanyId
      , workerId: row.workerId
      , projectId: projectId
      , firstPartnerCompanyId: row.firstPartnerCompanyId
      , dailyWorkStatus: row.dailyWorkStatusList[index]
    }
    dispatch(Module.setModalState(attendanceInfo));
    // 労働時間編集ダイアログの表示
    dispatch(Module.reflectModalState(true));
  }

  /**
   * 労働時間入力テキストボックスのフォーカスアウト時
   * @param value 
   */
  const onBlurWorkingTime = (value: string) => {
    let regexNumOnly12 = RegExp('^([0-9]{1,2})$');
    let regexNumOnly34 = RegExp('^([0-9]{1,2})([0-9]{2})$');
    let regex1colon = RegExp('^([0-9]{1,2}):([0-9]{1,2})$');

    let formatVal = "";
    if (regexNumOnly12.test(value)) {
      let mch = regexNumOnly12.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":00";
      }
    } else if (regexNumOnly34.test(value)) {
      let mch = regexNumOnly34.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":" + mch[2];
      }
    } else if (regex1colon.test(value)) {
      let mch = regex1colon.exec(value);
      if (mch !== null) {
        formatVal = ("0" + mch[1]).slice(-2) + ":" + ("0" + mch[2]).slice(-2);
      }
    }

    form.setFieldsValue({ workingTime: formatVal });
  }

  /**
   * Enterキー押下時
   */
  const onPressEnter = () => {
    let value = form.getFieldValue('workingTime');
    onBlurWorkingTime(value);
  }

  /**
   * 確定ボタン押下
   */
  const onClickDecision = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 入力された労働時間の正当性のチェック
    let value = form.getFieldValue('workingTime');

    let regexTime = RegExp("^(2[0-3]|[0-1][0-9]):[0-5][0-9]$");

    if (value !== "" && !regexTime.test(value)) {
      // 入力された労働時間が時間として正しい値ではない
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_INVALID_WORKING_TIME")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setModalMessages([setMessage]));
      return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // リクエストデータの作成
    let putData: Model.editWorkingTime.Request = {
      workRecordSubmissionHistoryId: info.workRecordSubmissionHistoryId
      , primeCompanyId: info.primeCompanyId
      , belongCompanyId: info.belongCompanyId
      , workerId: info.workerId
      , projectId: info.projectId
      , firstPartnerCompanyId: info.firstPartnerCompanyId
      , yearMonth: formatUtils.formatDateToYearMonth(info.dailyWorkStatus.date)
      , day: formatUtils.formatDateToDay(info.dailyWorkStatus.date)
      , workingTime: value === "" ? null : value
      , updateDatetime: info.dailyWorkStatus.updateDatetime
    }

    dispatch(Module.setLoading(true));
    dispatch(Module.putEditAttendanceAsync(putData));
  }

  /**
   * キャンセルボタン押下
   */
  const onClickCancel = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: apiResult.status, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 労働時間編集ダイアログを閉じる
    dispatch(Module.reflectModalState(false));
  }

  /**
   * 資格一覧ダイアログの閉じるボタン押下
   */
  const onClickQualificationCancel = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: apiResult.status, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 資格一覧ダイアログを閉じる
    dispatch(Module.reflectQualificationModalState(false));
  }

  /**
   * 労働時間の確認ダイアログのキャンセルボタン押下
   */
  const onClickWorktimeConfirmCancel = () => {
    dispatch(Module.reflectWorktimeConfirmModalVisibleState(false));
  }

  /**
   * 労働時間の確認ダイアログの提出ボタン押下
   */
  const onClickWorktimeConfirmSubmit = () => {
    dispatch(Module.reflectWorktimeConfirmModalVisibleState(false));
    submitAttendance(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT);
  }

  /**
   * 資格一覧ダイアログを表示
   * @param row 
   */
  const onClickQualification = async (row: Model.WorkRecordDetailRow) => {

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // NowLoading表示
    dispatch(initApiResult());

    // API呼出引数
    let params: Model.getOriginalQualificationList.Request = {
      primeCompanyId: primeCompanyId,
      belongCompanyId: belongCompanyId,
      workerId: row.workerId,
      yearMonth: criteria.attendanceYearMonth,
    }
    
    // 同期でAPIを呼び出す（前に取得した値が画面に表示されないようにする）
    await dispatch(Module.getQualificationsHoldersPrimeQualificationListAsync(params));

    // 資格一覧ダイアログの表示
    dispatch(Module.reflectQualificationModalState(true));
  }

  /** 検索部 */
  const Criteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        initialValues={{
          "primeCertificationNo": criteria.primeCertificationNo
          , "workerLastName": criteria.workerLastName
          , "workerFirstName": criteria.workerFirstName
          , "workerLastNameKana": criteria.workerLastNameKana
          , "workerFirstNameKana": criteria.workerFirstNameKana
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "firstPartnerCompanyNameKana": criteria.firstPartnerCompanyNameKana
          , "approved": criteria.approved
          , "submitted": criteria.submitted
          , "remand": criteria.remand
          , "noSubmitted": criteria.noSubmitted
        }}>
        <div style={{ float: "right" }}>
          <div className={styles.criteriaInputField}>
            <CriteriaForm.Item name="primeCertificationNo" id="primeCertificationNo">
              <Input
                type="text"
                placeholder="元請認定番号"
                maxLength={CONSTANTS.PRIME_CERTIFICATION_NO_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerLastName" id="workerLastName">
              <Input
                type="text"
                placeholder="氏名（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerFirstName" id="workerFirstName">
              <Input
                type="text"
                placeholder="氏名（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerLastNameKana" id="workerLastNameKana">
              <Input
                type="text"
                placeholder="氏名カナ（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerFirstNameKana" id="workerFirstNameKana">
              <Input
                type="text"
                placeholder="氏名カナ（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyName" id="firstPartnerCompanyName">
              <Input
                type="text"
                placeholder="一次協力会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
          </div>

          <div className={styles.criteriaSearchField}>
            <CriteriaForm.Item name="approved" id="approved" valuePropName="checked">
              <Checkbox className={styles.checkBox}>
                承認済
          </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="submitted" id="submitted" valuePropName="checked">
              <Checkbox className={styles.checkBox}>
                提出済
          </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="noSubmitted" id="noSubmitted" valuePropName="checked">
              <Checkbox className={styles.checkBox}>
                未提出
          </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="remand" id="remand" valuePropName="checked">
              <Checkbox className={styles.checkBox}>
                差戻
          </Checkbox>
            </CriteriaForm.Item>

            <AuthButton
              name={"検索"}
              shape={"round"}
              htmlType="submit"
            />
          </div>
        </div>

      </CriteriaForm>
    )
  }

  /** メッセージ部 */
  const Message = () => {
    if (modalVisibleState || qualificationModalVisibleState) {
      return <></>
    } else {
      return <CommonMessage  searchNoDataName="作業員" />
    }
  }

  /** 一覧ヘッダー部 */
  const AttendanceDetailHeader = () => {
    return (
      <div style={{ whiteSpace: "nowrap" }} className={styles.searchGroup}>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle}>勤怠年月：</div>
          <div>
            <label style={{ color: "#27a69b", margin: "4px" }} onClick={(e) => onChangeMonth(-1)}><LeftSquareFilled /></label>
            {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月
              <label style={{ color: "#27a69b", margin: "4px" }} onClick={(e) => onChangeMonth(1)}><RightSquareFilled /></label>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>元請会社：</div>
          <div>{primeCompanyName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>作業所：</div>
          <div>{projectName}</div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>所属会社：</div>
          <div>{belongCompanyName}</div>
        </div>
      </div>
    )
  }

  /** ボタン部 */
  const AttendanceButton = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          onClick={onClickBack}
          className={AuthButtonStyles.backButtonFixed}
          style={{ margin: "10px" }}
        />

        <AuthButton
          name={"提出"}
          authorities={[
            AuthButtonAuthorities.partnerAttendanceSubmitAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE ? true : existSubmittedChecked ? true : existNotSubmittedChecked ? false : true}
          style={{ margin: "10px" }}
        />

        <AuthButton
          name={"提出取消"}
          authorities={[
            AuthButtonAuthorities.partnerAttendanceSubmitAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL)}
          className={AuthButtonStyles.partnerAuthButtonFixed}
          disabled={editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE ? true : existNotSubmittedChecked ? true : existSubmittedChecked ? false : true}
          style={{ margin: "10px" }}
        />
      </FooterButtonArea>
    );
  }

  /**
   * ページングボタンを描画
   * @param page 
   * @param type 
   * @param originalElement 
   */
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  /** 資格一覧ダイアログ表示内容 */
  const QualificationModalContent = () => {
    // ダイアログ表示時の資格一覧取得API実行後、APIエラーメッセージが設定されているかどうか
    let isApiSuccess: boolean = CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode;
    if (isApiSuccess) {
      // API正常終了 
      return (
        <div className={styles.contentArea} >
          {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月時点で保持している資格一覧

          {qualificationList.length > 0 ?
            <Content
              style={{ margin: "auto", textAlign: "left" }}
              hasapicall={"false"}>
              <Table
                columns={qualificationDialogColumns}
                size='small'
                dataSource={qualificationList}
                pagination={false}
                className="hoverNotBackground"
                style={{ marginLeft: "20px" }}
                bordered
              />
            </Content> : <div className={styles.qualificationModalMessage}><ExclamationCircleOutlined className={styles.iconSize} style={{ marginRight: "6px" }} />保有資格はありません</div>
          }
        </div>
      );
    } else {
      // APIエラー有
      return (
        <div className={styles.contentArea} >
          {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月時点で保持している資格一覧
          <CommonMessage isModal={true} />
        </div>
      );  
    }
  }

  return (

    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <Criteria />
        <Message />
        <div style={{ textAlign: "left", fontSize: "25px", color: "red" }}>
          {isContainInvalidInOutTime ? "入場時間より退場時間が早い作業日があるため、通門設定に問題がないかを元請会社様にお問い合わせください。": ""}
        </div>
        <AttendanceDetailHeader />
        {0 < total && 0 < dataList.length ?
          <>
            {editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE && !existNotSubmitted && dataList.length > 0 ?
              <>
                <div style={{ float: "left" }}>提出済の勤怠情報の操作が必要な場合は、対象となる勤怠情報を提出した元請会社のご担当者にご連絡ください。</div>
                <br />
              </>
              : <></>}
            <div style={{ float: "left" }}>グリーンサイト上で入退場情報登録を申請した場合、元請会社が承認してからスキルマップサイトにデータが反映されるまで、しばらく時間かかることがあります。</div>
            <div style={{ float: "right" }}>－...該当の日付に有効な資格が1つも存在しない</div>
            <Table
              size="small"
              className="hoverNotBackground"
              columns={columns}
              dataSource={dataList}
              pagination={{
                total: total
                , defaultPageSize: 10
                , current: criteria.offset / criteria.limit + 1
                , pageSize: criteria.limit
                , pageSizeOptions: ['5', '10', '20']
                , showSizeChanger: true
                , showTotal: (total, range) => `${total}件中、${criteria.offset + 1}件目から${total <= criteria.offset + criteria.limit ? total : criteria.offset + criteria.limit}件目を表示`
                , itemRender: pageItemRender
                , locale: { items_per_page: '件/ページ' }
              }}
              onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters)}
            >
            </Table>
          </> : <></>
        }
        <MergeCompanyAnnotations
          attendanceList={dataList}
          mergeCompanyAttendanceFlgName={"abolishCompanyFlag"}
        />
      </Content>
      <AttendanceButton />
      <Footer />

      <Modal
        visible={modalVisibleState}
        footer={[
          <AuthButton
            name={"キャンセル"}
            size={"large"}
            shape={"round"}
            onClick={onClickCancel}
            className={AuthButtonStyles.backButtonFixed}
            style={{ margin: "10px" }}
          />,
          <AuthButton
            name={"確定"}
            size={"large"}
            shape={"round"}
            onClick={onClickDecision}
            className={AuthButtonStyles.partnerAuthButtonFixed}
            loading={isLoading}
            style={{ margin: "10px" }} />
        ]}
        destroyOnClose
        closable={false}
        width={350}>
        <Layout>
          <CommonMessage isModal={true} />
          <Content
            style={{ margin: "auto", textAlign: "right" }}
            hasapicall={"false"}>
            <Form
              form={form}
              initialValues={{
                "workingTime": info.dailyWorkStatus.workingTime
              }}
            >
              <Form.Item label="日付">
                {formatUtils.formatDateToYearsAndDayWeek(info.dailyWorkStatus.date)}
              </Form.Item>
              <Form.Item label="入場時間">
                {formatUtils.formatDateToTime(info.dailyWorkStatus.firstInTime)}
              </Form.Item>
              <Form.Item label="退場時間">
                {formatUtils.formatDateToTime(info.dailyWorkStatus.lastOutTime)}
              </Form.Item>
              <Form.Item name="workingTime" label="労働時間">
                <Input
                  type="text"
                  onBlur={(e) => onBlurWorkingTime(e.target.value)}
                  onKeyPress={((e) => {
                    if (e.key === "Enter") {
                      onPressEnter();
                      e.preventDefault()
                    }
                  })}
                  style={{ width: "100px", textAlign: "right" }}
                  maxLength={CONSTANTS.WORKING_TIME_MAX_LENGTH}
                />
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </Modal>

      {/* 資格一覧ダイアログ */}
      <Modal
        visible={qualificationModalVisibleState}
        footer={[
          <AuthButton
            name={"閉じる"}
            size={"large"}
            shape={"round"}
            onClick={onClickQualificationCancel}
            className={AuthButtonStyles.backButtonFixed}
            style={{ margin: "10px" }}
          />,
        ]}
        destroyOnClose
        closable={false}
        width={600}
        >
        <Layout>
          <QualificationModalContent />
        </Layout>
      </Modal>

      <Modal
        visible={worktimeConfirmModalVisibleState}
        footer={[
          <AuthButton
            name={"労働時間の修正に戻る"}
            size={"large"}
            shape={"round"}
            onClick={onClickWorktimeConfirmCancel}
            className={AuthButtonStyles.backButtonFixed}
            style={{ margin: "10px" }}
          />,
          <AuthButton
            name={"このまま提出する"}
            size={"large"}
            shape={"round"}
            onClick={onClickWorktimeConfirmSubmit}
            className={AuthButtonStyles.partnerAuthButtonFixed}
            style={{ margin: "10px" }} />
        ]}
        destroyOnClose
        closable={false}
        width={"90%"}>
        <Layout>
          <Content
            style={{
              margin: "auto",
              whiteSpace: "pre-wrap",
              fontSize: "30px",
            }}>
            {worktimeConfirmText }
          </Content>
        </Layout>
      </Modal>
    </Layout>
  );
}


